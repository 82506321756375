
import { Component, Prop, Vue } from 'vue-property-decorator';
import ArrowRightOutlined from '@/icons/ArrowRightOutlined.vue';
import { Row, Col } from 'ant-design-vue';
@Component({
    name: 'CmsMenu',
    components: { ArrowRightOutlined, Col, Row },
})
export default class CmsMenu extends Vue {
    @Prop() private menuData: any;

    private get isSettingsHomepage() {
        return this.$route.name === 'settings';
    }
}
